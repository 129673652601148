<template>
  <div class="ml-30 t-20">
    <settingItem title="防截屏水印" ref="settingItemRef" iconString="fangjieping" class="mt-20" :data="state.water"
      @Emit="waterSet" :disabled="true"></settingItem>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import settingItem from "./settingItem.vue"
const settingItemRef = ref()
let state = reactive({
  water: false,//水印
});
// 水印设置
const waterSet = ((value) => {

})
</script>

<style lang="scss" scoped>

</style>