<template>
  <tablebox v-loading="loading" :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background"
    class="dp-f">
    <choose class="mr-20" @Emit="butemit" :Data="state.data"></choose>
    <div class="bg-fff w100 pb-10">
      <div class="head_title pl-20 pr-20 b-b1 h-60 dp-f ai-c jc-sb">
        <div>{{ state.data[state.butIndex].title }}</div>
        <oabutton width='92' height='32' title="新建类型" CSStype=2 style="border-radius:4px;" v-if="state.butIndex == 1" @buttonclick="addType"></oabutton>
      </div>
      <sign-provider v-if="state.butIndex == 0"></sign-provider>
      <professionType v-else-if="state.butIndex == 1" ref="professionTypeRef"></professionType>
      <!-- <interest v-else-if="state.butIndex == 2"></interest> -->
      <!--  { title: '意愿表达设置' }, -->
      <not-screen-shot v-else-if="state.butIndex == 2"></not-screen-shot>
    </div>
  </tablebox>
</template>
<script setup>
import { reactive, ref, unref } from 'vue'
import choose from "@/components/sidebar/choose.vue"
import signProvider from './components/signProvider.vue'//公司信息设置
import professionType from './components/professionType.vue'//业务类型管理
import interest from './components/interest.vue'//意愿表达设置
import notScreenShot from './components/notScreenShot.vue'//防截屏水印设置
const loading = ref(false);//loading 显示
const professionTypeRef=ref()//业务类型管理ref
const state = reactive({
  butIndex: 0,//按钮选中下标
  data: [{ title: '公司信息设置' }, { title: '合同类型管理' }, { title: '防截屏水印设置' }]
})
// 新建类型
const addType=()=>{
  unref(professionTypeRef).addType()
}
// 按钮返回
const butemit = ((el) => {
  state.butIndex = el
  // console.log('按钮返回',el)
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

::v-deep .head_title {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
</style>